.stock-card {
    min-width: 480px;
    min-height: 80px;
    background-color: #2e4a62;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 25px;
    font-size: 14pt;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.stock-card-body {
    display: flex;
    flex-direction: row;
}

.stock-card input {
    font-size: 16px;
}

.stock-card-input-headers {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.stock-card-input-line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.stock-card-input-line input {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 3px;
    border: 0;
    min-height: 16pt;
    text-align: end;
    padding-right: 10px;
}

.stock-card-input-line.title input {
    text-align: center;
    padding-right: 0;
}

.stock-change-input {
    max-width: 40px;
}

.title-bar {
    background-color: #365773;
    min-height: 30px;
    width: calc(100% + 40px);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: -20px;
    margin-left: -20px;
    text-align: end;
}

.close-button {
    font-size: 24pt;
    font-weight: bold;
    color: firebrick;
    margin-right: 18px;
    cursor: default;
}

.close-button:hover {
    color: crimson;
    cursor: pointer;
}

.stock-card .price input {
    width: 10ch;
}
.stock-card .amount input {
    width: 5ch;
}

@media (max-width: 560px) {
    .stock-card {
        min-width: 0;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        font-size: 12pt;
        padding: 0 5px 10px;
        margin: 15px 0 10px;
    }
    .stock-card-body {
        display: flex;
        flex-direction: column;
    }
    .stock-card-input-headers {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .stock-card-input-line {
        display: flex;
        flex-direction: column;
    }
    .title-bar {
        background-color: #365773;
        min-height: 30px;
        width: calc(100% + 10px);
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        margin-top: 0;
        margin-left: -5px;
        text-align: end;
    }
}